@charset "UTF-8";
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800);


html, body { 
	height: 100%; 
	font-family: 'Helvetica Neue', Arial, sans-serif !important;
	background: #fff;
    color: #666666;
    line-height: 18px;
    font-size: 14px;
    text-rendering: normal !important;
}

.placeholder { color: #ededed; }
.nm_studios_reveal { display:none !important; }

.breakword{
	-ms-word-break: break-all;
	     word-break: break-all;
	     word-break: break-word;
	
	-webkit-hyphens: auto;
	   -moz-hyphens: auto;
	        hyphens: auto;
}


.video_separator{
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #ffffff;
}

.hide{display: none !important;}
.show{display: block !important;}
.alert-danger{ background-color: #f2dede; border-color: #ebccd1; color: #a94442; }
.alert-success{ background-color: #dff0d8; border-color: #d6e9c6; color: #3c763d; }


h1, h2, h3, h4, h5, h6 { font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;}
.bold { font-weight: 600; }
.table { display: table; margin: 0 auto; }
.clear { clear: both; }
p{
	font-family: inherit;
    font-size: 1rem;
    margin-bottom: 1.25rem;
    font-weight: normal;
    letter-spacing: 0.4px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-rendering: auto !important;
}
a{color: #000;}
a:hover{color: #8A0726;}

select, input[type='text'], input[type='email']{color: #000;font-size: 12px;}
select:focus option{background-color: #fff;color: #000;padding-left: 5px;padding-right: 5px;}

.nomargin { margin: 0 !important; padding: 0; }

.nopadding{padding-left:  0 !important;padding-right:  0 !important;}
.nopadding-right{padding-right:  0 !important;}
.nopadding-left{padding-left:  0 !important;}

.mr5{margin-right: 5px;}
.mr10{margin-right: 10px !important;}
.mr15{margin-right: 15px;}
.mr20{margin-right: 20px;}

.ml5{margin-left: 5px !important;}
.ml10{margin-left: 10px !important;}
.ml15{margin-left: 15px !important;}
.ml20{margin-left: 20px !important; }

.mt0{margin-top: 0px !important;}
.mt5{margin-top: 5px !important;}
.mt10{margin-top: 10px !important;}
.mt15{margin-top: 15px !important;}
.mt20{margin-top: 20px !important; }
.mt90{margin-top: 90px !important; }

.mb0{margin-bottom: 0 !important;}
.mb5{margin-bottom: 5px !important;}
.mb10{margin-bottom: 10px !important;}
.mb15{margin-bottom: 15px !important;}
.mb20{margin-bottom: 20px !important; }

.pr5{padding-right: 5px;}
.pr10{padding-right: 10px !important;}
.pr15{padding-right: 15px;}
.pr20{padding-right: 20px;}

.pl5{padding-left: 5px;}
.pl10{padding-left: 10px !important;}
.pl15{padding-left: 15px;}
.pl20{padding-left: 20px;}

#mapping_canvas img { max-width: none !important; }


/*---------------------------- FOUNDATION OVERWRITE ----------------------------*/
.row {max-width: 1170px; }
hr {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #ffffff;
}

/*---------------------------- Navigation --------------------------------*/
header{padding: 20px 0;color: #780023;}
.top-bar{background-color: #780023;color: #fff;}
.top-bar-section ul li{background: none;}
.top-bar-section li:not(.has-form) a:not(.button){background: none;font-size: 14px;}
.top-bar-section li.active:not(.has-form) a:not(.button){background: #400013 !important; }
.top-bar-section li.active:not(.has-form) a:not(.button):hover{background: #400013 !important;}

/*---------------------------- Home Page --------------------------------*/
.home_upper_section{ background-color: #e5e5e5; border-bottom: 1px solid #fff;font-weight: bold;}
.home_lower_section{ margin: 0; position: relative; background: #fff; padding: 35px 0;}
.home_lower_section:before {
    background-color: #f3f3f3;
    background-image: -moz-linear-gradient(top, #ebebeb, #ffffff);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ebebeb), to(#ffffff));
    background-image: -webkit-linear-gradient(top, #ebebeb, #ffffff);
    background-image: -o-linear-gradient(top, #ebebeb, #ffffff);
    background-image: linear-gradient(to bottom, #ebebeb, #ffffff);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#ffffffff', GradientType=0);
    height: 180px;
    width: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.home_lower_section:after {
    background-color: #e3e3e3;
    background-image: -moz-linear-gradient(top, #e5e5e5, #e0e0e0);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#e5e5e5), to(#e0e0e0));
    background-image: -webkit-linear-gradient(top, #e5e5e5, #e0e0e0);
    background-image: -o-linear-gradient(top, #e5e5e5, #e0e0e0);
    background-image: linear-gradient(to bottom, #e5e5e5, #e0e0e0);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5', endColorstr='#ffe0e0e0', GradientType=0);
    height: 20px;
    width: 100%;
    content: "";
    position: absolute;
    top: -21px;
    left: 0;
}

.fSlide{max-width: 100%;min-width: 100%;min-height: 430px;z-index: 0;margin-bottom: 20px;}
.fSlide .bx-wrapper .bx-viewport{left: 0 !important;box-shadow: none !important;border: none !important;}
.front_slider{margin: 0 !important;}
.front_slider li{background-repeat: no-repeat;background-size: cover;background-position: center center;min-height: 430px;}

.content_home_snippet{position: relative;}
.content_home_snippet h1{display: block;}
.content_home_snippet img.featured{float: left;margin: 0 10px 10px 0;}

.search_header_action{
	background-color: #058db0;color: #fff;font-size: 25px;height: 41px;line-height: 41px;
	font-family: 'Helvetica Neue',Arial, sans-serif;font-weight: 200;	padding: 0 10px;margin-bottom: 15px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.search_container #main_search{ background-color: rgba(255, 255, 255, 0.5);padding: 0; -webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px; }

.search_container #main_search select{margin-bottom: 5px;}
.search_container #main_search input[type='text']{margin-bottom: 5px;}

.CurrencyOptions label{display: inline-block;margin-right: 10px;color: #000;}
.CurrencyOptions label input{margin-left: 10px;}
.rentsaleOptions label{display: inline-block;margin-right: 10px;color: #000;}
.rentsaleOptions label input{margin-left: 10px;}

.featuredControls{display: inline-block; margin-top: 21px; position: relative;}
.featuredControls span a{display: inline-block;font-size: 16px;background-color: #d9d9d8;padding: 5px;margin-left: 5px;color: #000;}

.recentControls{display: inline-block; margin-top: 0; position: relative;}
.recentControls span a{display: inline-block;font-size: 16px;background-color: #d9d9d8;padding: 5px;margin-left: 5px;color: #000;}

.search_container .search_header_action{margin-bottom: 5px;}
.search_container .rentsaleOptions label{font-size: 13px;color: #000;}
.search_container .rentsaleOptions label input{margin: 0 !important;}
.search_container .currencySearch{padding-right: 0.6rem !important;}
.search_container .pricerangeSearch{padding-left: 0.6rem !important;}

input[type='submit'],  input[type='reset']{
	-webkit-appearance: none;
    -moz-appearance: none;
}


.search_container input[type='submit'],
.search_container input[type='reset'],
#contact-form input[type='submit']{
	background-color: #8A0726;	
	border: none;
	border-top: 2px solid #B1072F;
	border-left: 1px solid #B1072F;
	width: 100%;
	color: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;	
	height: 30px;
	font-size: 14px;
	padding: 0 !important;
	margin: 3px 0;
	cursor: pointer;
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: bold;
	
}

.search_container input[type='submit']:hover,
.search_container input[type='reset']:hover,
#contact-form input[type='submit']:hover{background-color: #B1072F;}


.featured_block .bx-wrapper .bx-viewport{box-shadow: none !important;left: 0 !important;border: none;}
.mFeaturedBox .bx-wrapper .bx-viewport{box-shadow: none !important;left: 0 !important;border: none;}
.featured_properties{margin: 0 !important;list-style: none;margin: 0;padding: 0;}
.featured_properties li{float: left;width: 100%;min-height: 430px;}
.featured_properties li a{
	display: block;position: relative; transition: all .5s ease-in-out;overflow: hidden;
}
.featured_properties li a .property_image{width: 100%;min-height: 430px;background-repeat: no-repeat;background-size: cover;background-position: center center;}
.featured_properties li a .property_content{
	position: absolute;bottom: 10%;right: 10%;
	background-color: #8A0726;border-radius: 5px;color: #ffffff;overflow: hidden;
	max-width: 250px;padding: 20px;
}

.recent_properties{margin: 0 !important;list-style: none;margin: 0;padding: 0;}
.recent_properties li{float: left;}
.recent_properties li .userBlockHolder a{background-repeat: no-repeat;background-size: cover;background-position: center center;height: 160px;display: block;position: relative;width: 100%;overflow: hidden;}
.recent_properties li .userBlockHolder a img{width: 100%;min-height: 160px;}

.recent_properties li .userBlockHolder a .rentSale{
	color: #fff;background-color: #ea1d22;padding: 0 10px;height: 20px;line-height: 20px;font-size: 10px;
	position: absolute;top: 10px;right: 10px;
	text-transform: uppercase;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;	
}
.recent_properties li .userBlockHolder a .propertyStatus{position: absolute;width: 100%;color: #fff;text-transform: uppercase;text-align: center;bottom: 0;font-size: 10px;height: 20px;line-height: 20px;background-color: #17125f;}

.recent_properties li .userBlockHolder .property_details{background-color: #eeeeee;color: #282828;font-family: Arial,sans-serif;padding: 10px;line-height: 16px;}
.recent_properties li .userBlockHolder .property_details .priceProperty{font-size: 14px;font-weight: bold;}
.recent_properties li .userBlockHolder .property_details .innerBoxControl{min-height: 70px;}

.recent_properties li .userBlockHolder .property_details .ammenitiesProperty{height: 30px;margin-top: 5px;}
.recent_properties li .userBlockHolder .property_details .ammenitiesProperty .item{float: left;width: 50%;line-height: 30px;height: 30px;}
.recent_properties li .userBlockHolder .property_details .ammenitiesProperty .item img{float: left;}
.recent_properties li .userBlockHolder .property_details .ammenitiesProperty .item.bedroom img{margin-top: 7px;margin-right: 4px;}
.recent_properties li .userBlockHolder .property_details .ammenitiesProperty .item.bathroom img{margin-top: 2px;margin-right: 4px;}

.recent_properties li .userBlockHolder .property_details .ammenitiesProperty .item span{float: left;}

/*---------------------------- Search Area --------------------------------*/

.search_class input::-webkit-input-placeholder { color: rgba(0, 0, 0, 1); }
.search_class input::-moz-placeholder { color: rgba(0, 0, 0, 1); } /* firefox 19+ */
.search_class input:-ms-input-placeholder { fcolor: rgba(0, 0, 0, 1); } /* ie */
.search_class input:-moz-placeholder { color: rgba(0, 0, 0, 1); }
.search_class select{color: rgba(0, 0, 0, 1);}

.search_class { min-height: 190px; margin-top: 200px; z-index: 10; }
.search_class .search-button { background-color: #003366; font-size: 100% !important; margin-bottom: 0; padding: 0  !important; 
transition: all 0.5s ease; }

.search_class .search-button:hover { background-color: #468eed; }
.search_class .tabs-content > .content p#search_text { font-size: 140%; margin-bottom: 15px; }

.tabs-content > .content { background: transparent url('../images/faint-white.png') repeat left top; color: #003366; padding: 15px 5px; -webkit-border-radius: 5px;-moz-border-radius: 5px;border-radius: 5px;}
.tabs-content > .content p { font-size: 12px;  font-weight: 600; line-height: 14px; margin-bottom: 20px; }
	
#property_type_tab.tabs{margin-bottom: 15px !important;}
#property_type_tab.tabs dd { margin: 10px 5px 0 0px;outline: none !important; }
#property_type_tab.tabs dd.active a { background: none repeat scroll 0 0 #143c7f; color: #fff; }
#property_type_tab.tabs dd a:hover { background-color: #143c7f; }
#property_type_tab.tabs dd > a { 
	background: none repeat scroll 0 0 #3e71ab; color: #fff; padding: 0px 10px; font-size: 12px; height: 25px;line-height: 25px;
	text-transform: uppercase; font-weight: 400; -webkit-font-smoothing: antialiased; 
	transition: all 0.3s ease; outline: none !important;
}


/*.agent_details_form .form_container .tabs .tab-title.active > a{
	background-color: #133c7f !important;height: 20px;line-height: 20px;position: relative;
}*/

#property_type_tab.tabs dd.active a:after, 
#property_type_tab.tabs dd.active a:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

#property_type_tab.tabs dd.active a:after {
	border-color: rgba(19, 60, 127, 0);
	border-top-color: #143c7f;
	border-width: 3px;
	margin-left: -3px;
}
#property_type_tab.tabs dd.active a:before {
	border-color: rgba(19, 60, 127, 0);
	border-top-color: #143c7f;
	border-width: 7px;
	margin-left: -7px;
}

.search_class input[type='text']{height: 32px;margin-bottom: 5px;}
.search_class.front input[type='text']{height: 30px;margin-bottom: 6px;}
.search_class.inner .mask { background: url("../images/black_arrow.png") no-repeat scroll 90% center #ffffff;display: block; margin-bottom: 0px; overflow: hidden; width: 100%; }


.bedbath{list-style: none;margin: 0;padding: 0;}
.bedbath li{width: 50%;float: left;padding: 0 5px 0 0;}
.bedbath li select{margin-bottom: 5px;}
.bedbath li:last-child{padding: 0 0 0 5px !important;}

.side_criteria_search{background-color: #FAFAFA;border: 1px solid #ccc;padding: 10px;margin: 10px 0;}
.side_criteria_search .header_title{text-transform: uppercase;margin-bottom: 10px;}
.side_criteria_search .criteria_list{list-style: none;padding: 0;margin: 10px 0 10px 0;}
.side_criteria_search .criteria_list li{border-bottom: 1px solid rgba(0,0,0,0.1);border-top: 1px solid rgba(255,255,255,0.1);}
.side_criteria_search .criteria_list li:first-child{border-top: none !important;}
.side_criteria_search .criteria_list li:last-child{border-bottom: none !important;}

.side_criteria_search .criteria_list li label{font-size: 12px;padding: 3px 0;}
.side_criteria_search .criteria_list li label input{margin: 0 ;}

.social.home{list-style: none;padding: 0;margin: 10px 0;}
.social.home li{display: inline-block;margin-right: 5px;}




.property_search_list li:nth-child(3n){padding: 0 0.625rem 0.4rem 0.2rem;}
.property_search_list li:nth-child(3n+1){padding: 0 0.2rem 0.4rem 0.625rem;}
.property_search_list li:nth-child(3n-1){padding: 0 0.2rem 0.4rem 0.2rem;}
/*.property_search_list li:nth-child(3n){padding: 0 0.3rem 1.25rem 0rem;}
.property_search_list li:nth-child(3n-1){padding: 0 0.3rem 1.25rem 0.3rem;}
.property_search_list li:nth-child(3n+1){padding: 0 0rem 1.25rem 0.3rem;}*/

.property_search_list li a .propertyImageContainer{
	height: 160px;background-repeat: no-repeat;background-size: cover;background-position: center center;position: relative;
	overflow: hidden;
}

.property_search_list li:hover a .propertyImageContainer{opacity: 0.85;}

.property_search_list li a .propertyImageContainer .property_rentsale{
position: absolute;top: 10px;right: 10px;
background-color: #ea1d22;color: #fff;
font-size: 10px;padding: 3px 8px;text-transform: uppercase;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
}

.property_search_list li a .propertyImageContainer .property_status{position: absolute;bottom: 0;width: 100%;font-size: 10px;text-transform: uppercase;text-align: center;background-color: #780023;color: #fff;height: 25px;line-height: 25px;}
.property_search_list li a .contentContainer{border-bottom: 1px solid #d3d3d3;background-color: #eee;padding: 10px;color: #282828;font-size: 12px;min-height: 135px;}
.property_search_list li a .contentContainer #mainPrice{font-size: 14px;font-weight: bold;}
.property_search_list li a .contentContainer .mini_amenities{margin-top: 10px;color: #666666;font-size: 12px;}
.property_search_list li a .contentContainer .mini_amenities i{font-size: 14px;}
.property_search_list li a .contentContainer .mini_amenities img{float: left;margin-right: 3px;width: 15px;}


/*--------------------------- AGENT PERSONAL PAGE --------------------------*/

.top_agent_search { height: 54px; background-color: #053962; color: #fff; }
.agent_property_details ul { list-style: none; margin: 0; padding: 0; }
.agent_property_details ul li { float: left; padding: 0 10px; font-size: 12px; text-transform: uppercase; height: 54px; line-height: 54px; }
.agent_property_details ul li a { font-size: 15px; margin-left: 5px; color: #fff; }
.agent_property_details ul li a .count{}
.agent_social_links{ padding-right: 0; }
.agent_social{ background-color: #c8d1d5; margin: 17px auto 0 auto; display: table; height: 37px; line-height: 37px; width: 100%; }
.agent_social .table { display: table; margin: 0 auto; }

.agent_social .table ul {
	list-style: none;
	margin: 5px 0 0 0;
	padding: 0;
}
.agent_social .table ul li {
	margin-right: 10px;
	float: left;
}
.agent_information {
	background-color: rgba(255, 255, 255, 0.5);
	padding-top: 20px;
}
.agent_information_details {
	margin-left: 20px;
	margin-bottom: 15px;
}
.agent_information_details .cover {
	width: 124px;
	height: 124px;
	border-radius: 50%;
	background-color: #053962;
	padding: 5px;
	text-align: center;
	float: left;
	margin-right: 10px;
}
.agent_information_details .cover .agent_image {
	width: 114px;
	height: 114px;
	border-radius: 50%;
	overflow: hidden;
	margin: 0 auto;
}
.agent_information_details .cover .agent_image img {
	width: 100%;
	min-height: 114px;
}
.agent_information_details .text {
	float: left;
	color: #053962;
	font-size: 14px;
	margin-top: 20px;
}
.agent_information_details .text .agentName {
	font-weight: 500;
	font-size: 17px;
}
.agent_information_details .text .agentTitle {
	
}
.agent_information_details .text .agentEmail {
	font-size: 12px;
}
.agent_information_details .text .agentLicense {
	font-size: 12px;
}
.agent_information_details .text .agentWebsite {
	font-size: 12px;
}
.agentProfile {
	color: #053962;
	font-size: 14px;
	line-height: 15px;
	margin-left: 20px;
}
.specialist_logos {
	margin-top: 20px;
	margin-left: 10px;
}
.agent_information_details:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.agent_side_piece .banners a {
	margin: 0 auto 10px auto;
}
.agentProperties .grid_search {
	padding: 0;
	margin: 0px;
}
.agentProperties .grid_search .box {
	background-color: #1b3b6b;
	border: 1px solid #d1d8e1;
	color: #fff;
	overflow: hidden;
}
.agentProperties .grid_search .box .property_image_holder {
	position: relative;
	overflow: hidden;
	height: 150px;
}
.agentProperties .grid_search .box .property_image_holder .property_img {
	min-height: 150px;
	width: 100%;
}
.agentProperties .grid_search .box .property_information {
	font-size: 13px;
	text-transform: uppercase;
	line-height: 18px;
	min-height: 165px;
}
.agentProperties #main_property_container .grid_search {}

.agentProperties #main_property_container .grid_search > li { }
.agentProperties #main_property_container .grid_search > li:nth-of-type(3n){ padding: 0 0 1.25rem 0.625rem !important; }
.agentProperties #main_property_container .grid_search > li:nth-of-type(3n+1){ padding: 0 0.625rem 1.25rem 0 !important; }

.agentProperties #main_property_container .grid_search .box {
	background-color: #1b3b6b;
	border: 1px solid #d1d8e1;
	color: #fff;
	overflow: hidden;
}
.agentProperties #main_property_container .grid_search .box .property_image_holder {
	position: relative;
	overflow: hidden;
	height: 150px;
}
.agentProperties #main_property_container .grid_search .box .property_image_holder .property_img {
	min-height: 150px;
	width: 100%;
}



.agentDetails.search_page img {
	margin-right: 10px;
	width: 42px;
}
.topSection {
	color: #1b3b6b;
	line-height: 30px;
	font-size: 11px;
	border-bottom: 1px dotted #8c8c8c;
	padding: 3px 0 3px 0;
}
.topSection select {
	color: #1b3b6b;
	font-size: 10px;
	margin: 0;
	height: auto;
	width: 100px;
	background-color: #fff;
	border: 1px solid #cccccc;
	height: 30px;
}
.range {
	text-transform: uppercase;
	margin-right: 5px;
}
.topButtons a {
	color: #1b3b6b;
	padding: 0px 10px;
	text-transform: uppercase;
	border: 1px solid #ccc;
	margin: 0;
	float: right;
	width: 110px;
	text-align: center;
	height: 30px;
	font-weight: 400;
	font-size: 11px;
}
.topButtons a.active {
	background-color: #1b3b6b;
	color: #fff;
	transition: all 0.5s ease;
}
.topButtons a.active:hover {
	background-color: #4894f9;
	color: #ffffff;
}
.topButtons a.inactive {
/*-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
	filter: alpha(opacity=50);
	-moz-opacity:0.5;
	-khtml-opacity: 0.5;
	opacity: 0.5;
	cursor:default;
	*/
}
.grid_search { padding: 0; margin: 0px; }
.grid_search > li { padding: 0 2.5px !important; margin: 5px 0 5px 0; }
.grid_search > li:nth-of-type(3n+1){padding-left: 0 !important;}
.grid_search > li:nth-of-type(3n){padding-right: 0 !important;}
.grid_search > li a{display: block;}

/*.grid_search.end{margin-right: 0px;width: 33%;}*/
.grid_search .box { background-color: #1b3b6b; border: 1px solid #d1d8e1; color: #fff; }
.grid_search .box .property_image_holder { position: relative; overflow: hidden; height: 186px; }
.grid_search .box .property_image_holder .property_img { height: 186px; width: 100%; }
.grid_search .box .property_image_holder .property_agent {
	font-size: 11px;
	line-height: 16px;
	background: transparent url('../images/fog.png') repeat left top;
	padding: 10px;
	position: absolute;
	width: 100%;
	color: #1b3b6b;
	transition: all 0.2s ease-in-out 0s;
	margin-top: 0px;
	bottom: -100%;
	/*bottom: 0;*/
}
.grid_search .box .property_image_holder .luxury_status { 
	position: absolute; top:1px; left: 0px; z-index: 100; 
	background: transparent url('../images/luxury_property.png') no-repeat center center; width: 100%; 
}
.grid_search .box .property_image_holder .property_agent img {
	width: 44px;
	height: 46px;
}
.grid_search .box .property_image_holder .mlsprop.property_agent .agent_img_prop img {
	width: auto;
}
.grid_search .box .property_image_holder .mlsprop.property_agent .agent_img_content {
	width: 105px;
	padding-bottom: 0px;
}
.grid_search .box .property_image_holder .property_img {
	display: inline-block;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.grid_search .box:hover .property_image_holder .property_img {
	-webkit-animation-name: pop;
	animation-name: pop;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

.grid_search .box .property_image_holder:hover .property_agent{
	display:block !important;
	bottom: 0;
}
.grid_search .box .property_image_holder:hover .property_agent.ppstatus{
	display:block !important;
	bottom: 28px;
}

.grid_search .box .property_image_holder .property_status {
	background-color: #4894f9;
	color: #fff;
	text-align: center;
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 5px 0;
	font-size: 11px;
}
.grid_search .box .property_information {
	background-color: #1b3b6b;
	padding: 10px;
	font-size: 11px;
	line-height: 13px;
	position: relative;
	z-index: 0;
	min-height: 125px;
}
.grid_search .box .property_information .new_listing {
	background-color: #007236;
	color: #fff;
	text-transform: uppercase;
	padding:5px 8px;
	position: absolute;
	right: 0;
	top: 20px;
}
.grid_search li .property_fav_actions {
	width: 100%;
	background-color: #4894F9;
	border: 1px solid #d1d8e1;
	border-top: none;
	color: #fff;
	display: inline-block;
	text-align: center;
}
.grid_search li .property_fav_actions a {
	display: block;
	color: #fff;
	font-weight: bold;
	width: 100%;
	text-align: center;
	padding: 2px 0;
	transition: all 0.5s ease;
}
.grid_search li .property_fav_actions a img {
	margin-right: 4px;
}
.grid_search li .property_fav_actions a:hover { background-color: rgba(255, 255, 255, 0.4); }

.agentListings { background-color: #4894F9; padding: 0 !important; margin: 10px 0 0 0; }
.agentListings a { display: block; width: 50%; color: #fff; background-color: #4894F9; height: 25px; line-height: 25px; text-align: center; border-right: solid 1px #ccc; float: left; transition: all 0.5s ease; }
.agentListings a:last-child { border: none; }
.agentListings a:hover { background-color: #003366; color: #fff; }

/*------------------------------------------------- Property Details ------------------------------------------*/


#details_div .location{color: #666;font-size: 30px;line-height: 30px;font-family: Arial, sans-serif;font-weight: 100;}
#details_div .action_area2{background-color: #8A0726;margin-bottom: 15px;}
#details_div .action_area2 ul{list-style: none;margin: 0;padding: 0;}
#details_div .action_area2 ul li{float: left;border-right: 1px solid rgba(0,0,0,0.3);border-left: 1px solid rgba(255,255,255,0.1);}
#details_div .action_area2 ul li:last-child{border-right: none;}
#details_div .action_area2 ul li a{color: #fff;display: inline-block;padding: 0 10px;line-height: 30px;height: 30px;}
#details_div .action_area2 ul li a:hover{background-color: #B1072F;color: #fff;}
#details_div .action_area2 ul li div{color: #fff;background-color: #af0e32;display: inline-block;padding: 0 10px;line-height: 30px;height: 30px;}
#details_div .propertyStatusBanner{background-color: #EA1D22;border-radius: 3px; color: #fff; padding: 2px 8px;}
#details_div .description{color: rgba(40,40,40,0.76);font-size: 13px;line-height: 18px;border-bottom: 1px solid rgba(0,0,0,0.2);margin-bottom: 15px;padding-top: 30px;}


#image_div{position: relative;}
#image_div .rent_sale{position: absolute;top: 10px;right: 10px;background: #8A0726;color: #fff;padding: 5px 8px;text-transform: uppercase;}
#image_div .property_hover{line-height: 24px;position: absolute;bottom: 5px;left: 5px;right: 5px;background-color: #8A0726;color: #fff;padding: 10px;}
#image_div .property_hover .mainPrice{font-size: 24px;line-height: 24px;font-weight: 100;float: left;margin-right: 10px;}
#image_div .property_hover .allPrices .small_price{margin-right: 10px;}

.bx-next, .bx-prev{z-index: 1 !important;}
.property_image_slide .bx-wrapper{position: relative;}
.property_image_slide .bx-wrapper .bx-viewport { background: none; border: none; box-shadow: none; left: 0;}

.property_details_slider{list-style: none;padding: 0;margin: 0 !important;}
.property_details_slider li{min-height: 638px;background-repeat: no-repeat;background-size: cover;background-position: center center;}

.property_general_header2{font-size: 18px;margin-bottom: 10px;color: rgba(71,69,69,1);}

.exterior_ammenities .title, .interior_ammenities .title{font-size: 13px;margin: 10px 0;color: rgba(0, 0, 0,0.8);}
.exterior_ammenities ul, .interior_ammenities ul{list-style: none;padding: 0;}
.exterior_ammenities ul li, .interior_ammenities ul li{font-size: 12px;color: rgba(71,69,69,0.7);}



.header_title{color: #313131;font-size: 18px;font-family: 'Helvetica Neue', Arial, sans-serif;font-weight: 200;margin-bottom: 10px;}
.header_title .count{font-size: 14px;}

.price_comparison{margin-top: 20px;padding-top: 20px;border-top: 2px solid #31495d;}
.price_comparison .average_content{margin-bottom: 20px;}

.price_comparison .average_content .text{color: #313131;font-size: 13px;font-family: 'Helvetica Neue', Arial, sans-serif;font-weight: 200;margin-bottom: 8px;}
.price_comparison .average_content .average_stat{list-style: none;margin: 0;padding: 0;}
.price_comparison .average_content .average_stat li{border-top: 1px dotted rgba(0,0,0,0.1);clear: both;}

.price_comparison .average_content .average_stat li a{display: block;padding: 0 0 0 0;color: #333;height: 30px;line-height: 30px;}
.price_comparison .average_content .average_stat li a .count{float: right;}
.price_comparison .average_content .average_stat li a .count:hover{color: #00aef0;}
.price_comparison .average_content .average_stat li a .text{float: left;min-width: 100px;}
.price_comparison .average_content .average_stat li a .range{font-weight: 600;}


.similiar_properties li a .propertyImageContainer{
	height: 160px;background-repeat: no-repeat;background-size: cover;background-position: center center;position: relative;
	overflow: hidden;
}

.similiar_properties li:hover a .propertyImageContainer{opacity: 0.85;}

.similiar_properties li a .propertyImageContainer .property_rentsale{
position: absolute;top: 10px;right: 10px;
background-color: #ea1d22;color: #fff;
font-size: 10px;padding: 3px 8px;text-transform: uppercase;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
}

.similiar_properties li a .propertyImageContainer .property_status{position: absolute;bottom: 0;width: 100%;font-size: 10px;text-transform: uppercase;text-align: center;background-color: #780023;color: #fff;height: 25px;line-height: 25px;}
.similiar_properties li a .contentContainer{border-bottom: 1px solid #d3d3d3;background-color: #eee;padding: 10px;color: #282828;font-size: 12px;min-height: 135px;}
.similiar_properties li a .contentContainer #mainPrice{font-size: 14px;font-weight: bold;}
.similiar_properties li a .contentContainer .mini_amenities{margin-top: 10px;color: #666666;font-size: 12px;}
.similiar_properties li a .contentContainer .mini_amenities i{font-size: 14px;}
.similiar_properties li a .contentContainer .mini_amenities img{float: left;margin-right: 3px;width: 15px;}


.agent_box{background-color: #e5e5e5;border: 1px solid #d6d6e2;border-bottom: 1px solid #c6c6d7;margin-bottom: 10px;position: relative; margin-top: 85px;}


.agent_box.agent .agent_profile_picture{
	width: 90px;height: 90px;left: 0;right: 0;top: -45px;	
	margin: 0 auto;border: 1px solid #fff;-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background-color: #fff;position: absolute;background-repeat: no-repeat;background-size: cover;background-position: center center;
}
.agent_box.company .agent_profile_picture{
	width: 100%;height: 90px;
	background-repeat: no-repeat;background-size: cover;background-position: center center;
	background-size: 90%;background-color: #fff;left: 0;right: 0;border-bottom: 1px solid #d6d6e2;
}

.agent_box.company .content{padding-top: 0 !important;}
.agent_box .content{text-align: center;margin-top: 20px;padding-top: 30px;padding-bottom: 10px;}
.agent_box.company .content{margin-top: 10px !important;}
.agent_box .content .agentName{color: #000;font-size: 20px;line-height: 22px;font-weight: bold;}
.agent_box .content .agentTelephone{color: #333333;font-size: 13px;}
.agent_box .content .agentTelephone i{font-size: 18px;}
.agent_box .content .agentEmail{color: #790000;}


.property_courtesy{font-size: 10px;}
.property_courtesy .courtesy{opacity: 0.8;;}
.property_courtesy abbr{opacity: 0.5;}

/******GENERIC FORM********/
.sharing_text {
	margin-bottom: 10px;
}
.share_title {
	color: #fff;
	font-weight: 100;
	font-size: 20px;
	display: block;
	margin-top: 10px;
}
.cool_form {margin-top: 10px;margin-bottom: 0;}
.cool_form input[type='text'], .cool_form input[type='email'] {margin-bottom: 5px;}
.cool_form textarea {resize: none;height: 60px;}


.cool_form .error { font-size: 12px; margin-bottom: 5px; }
.cool_form input[type='text'].error, .cool_form input[type='email'].error {background-color: #F9F2F4 !important;color: #C7254E !important;}
.cool_form textarea.error {
	background-color: #F9F2F4 !important;
	color: #C7254E !important;
}
.cool_form label.error {display: none !important;}
.cool_form input::-webkit-input-placeholder { color: #666; }
.cool_form input::-moz-placeholder {color: #666;} /* firefox 19+ */
.cool_form input:-ms-input-placeholder { color: #666;} /* ie */
.cool_form input:-moz-placeholder {color: #666;}

.cool_form input.error::-webkit-input-placeholder { color: #666;}
.cool_form input.error::-moz-placeholder {color: #666;} /* firefox 19+ */
.cool_form input.error:-ms-input-placeholder {color: #666;} /* ie */
.cool_form input.error:-moz-placeholder {color: #666;}

.cool_form textarea::-webkit-input-placeholder {color: #666;}
.cool_form textarea::-moz-placeholder {color: #666;} /* firefox 19+ */
.cool_form textarea:-ms-input-placeholder {color: #666;} /* ie */
.cool_form textarea:-moz-placeholder {color: #666;}

.cool_form textarea.error::-webkit-input-placeholder {color: #666;}
.cool_form textarea.error::-moz-placeholder {color: #666;} /* firefox 19+ */
.cool_form textarea.error:-ms-input-placeholder {color: #666;} /* ie */
.cool_form textarea.error:-moz-placeholder {color: #666;}

.cool_form input[type='submit'] {background-color: #4894f9;border: none;text-transform: uppercase;color: #003366;padding:5px;width: 100%;font-size: 13px;margin: 10px 0 0 0;cursor: pointer;transition: all 0.3s ease;}
.cool_form input[type='submit']:hover { background-color: #78b2ff; }


.cool_form input[type='submit']{
	background-color: #8A0726;	
	border: none;
	border-top: 2px solid #B1072F;
	border-left: 1px solid #B1072F;
	width: 100%;
	color: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;	
	height: 30px;
	font-size: 14px;
	padding: 0 !important;
	margin: 3px 0;
	cursor: pointer;
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: bold;
}

.cool_form input[type='submit']:hover{background-color: #B1072F;}


.choices_contact { margin-bottom: 10px; }
.choices_contact label { float: left; font-size: 11px; color: #fff;}
.choices_contact label input { margin: 0 5px; }

#AgentForm_contact_option .rb {
	float: left;
	margin-right: 10px;
	line-height: 14px;
	display:block;
}
#AgentForm_contact_option .rb * {
	float: left;
}
#AgentForm_contact_option .rb label {
	color: #fff;
	font-size: 11px;
}
#AgentForm_contact_option .rb input {
	margin: 2px 0 0 2px;
}
#AgentForm_contact_option .rb:after, #AgentForm_contact_option:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
/*VACANCY MODULE*/
.selectOptions{}
.selectOptions label{float:left;margin-right: 20px;}

#VacancyForm_utech{float: left;}
#VacancyForm_utech .rb{float: left;}
#VacancyForm_utech .rb label{margin-right: 10px;float: left;}
#VacancyForm_utech .rb input{margin:3px 10px 0 0;float: left;}

#VacancyForm_motorcar{float: left;}
#VacancyForm_motorcar .rb{float: left;}
#VacancyForm_motorcar .rb label{margin-right: 10px;float: left;}
#VacancyForm_motorcar .rb input{margin:3px 10px 0 0;float: left;}

.fileOption{margin: 20px 0;}
.fileOption:after {     visibility: hidden;     display: block;     font-size: 0;     content: " ";     clear: both;     height: 0;     }
.fileOption label{float: left;margin-right: 10px;}
.fileOption input{float: left;width: auto;}

.fileOption .errorMessage{color: #F04124;font-size: 0.9rem;}


.image_hider {
	width: 100%;
	height: 450px;
}
.property_map {
	background-color: #fff !important;
	position: relative !important;
}
.listing_by {
	color: rgba(255, 255, 255, 0.7);
	font-size: 90%;
	margin-top: 20px;
	display: inline-block;
}
.listing_by abbr {
	color: rgba(255, 255, 255, 0.4);
}
.back_to_search a {
	display: block;
	background-color: #003366;
	color: #fff;
	text-transform: uppercase;
	margin-left: 10px;
	padding: 5px 8px;
	width: 130px;
	text-align: center; 
}
.text_content { color: #003366; background-color: #fff; padding: 15px; }
.text_content .description { text-align: justify; padding-right: 10px; }

.similar_properties li a .propertyImageContainer{
	height: 160px;background-repeat: no-repeat;background-size: cover;background-position: center center;position: relative;
	overflow: hidden;
}

.similar_properties li:hover a .propertyImageContainer{opacity: 0.85;}

.similar_properties li a .propertyImageContainer .property_rentsale{
position: absolute;top: 10px;right: 10px;
background-color: #ea1d22;color: #fff;
font-size: 10px;padding: 3px 8px;text-transform: uppercase;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
}

.similar_properties li a .propertyImageContainer .property_status{position: absolute;bottom: 0;width: 100%;font-size: 10px;text-transform: uppercase;text-align: center;background-color: #780023;color: #fff;height: 25px;line-height: 25px;}
.similar_properties li a .contentContainer{border-bottom: 1px solid #d3d3d3;background-color: #eee;padding: 10px;color: #282828;font-size: 12px;min-height: 135px;}
.similar_properties li a .contentContainer #mainPrice{font-size: 14px;font-weight: bold;}
.similar_properties li a .contentContainer .mini_amenities{margin-top: 10px;color: #666666;font-size: 12px;}
.similar_properties li a .contentContainer .mini_amenities i{font-size: 14px;}
.similar_properties li a .contentContainer .mini_amenities img{float: left;margin-right: 3px;width: 15px;}

.orbit-bullets-container {display: none;}

/** Pagination **/

.pagination{ display: table; border: 1px solid #ddd; }
.pagination ul{list-style: none;margin: 0;padding: 0;}
.pagination ul li{float: left;margin-right: 0px;border-right: 1px solid rgba(0, 0, 0, 0.1);}
.pagination ul li:last-child{border-right: none;}
.pagination ul li a{
     display:inline-block;
     cursor:pointer;
     color:#000;
     font-family:arial;
     font-size:12px;
     font-weight:bold;
     padding:3px 12px;
     text-decoration:none;
     background-color: #F0F0F0;
     
}
.pagination ul li a:hover { background-color: #FFC700; color: #8A0027;}
.pagination ul li.selected a{ background-color: #8A0027;color: #fff;}
.pagination ul li.previous{display: none;}
.pagination ul li.next{display: none;}

#loading {
	width: 100%;
}
#loading img {
	margin: 0 auto;
	margin-top: 150px;
	margin-bottom: 50px;
	display: block;
}
/***_______ERROR__________*****/
.button_oops {
	background-color: #780023;
	padding: 5px 10px;
	color: #fff;
	font-size: 14px;
	display: inline-block;
	line-height: 21px;
	border: solid 5px #fff;
}
.button_oops:hover {
	color: #fff;
	background-color: #780023;
}
.error_page {
	padding: 100px 0;
	text-align: center;
	color: #10264D;
}
.error_page .error_image {
	background: transparent url('../images/errorpageicon.png') no-repeat center center;
	height: 451px;
	width: 309px;
	margin:0 auto;
}
.error_page .error_content {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 100;
	line-height: 30px;
}

/***_______AGENT__________*****/
#agent_search {
}
#agent_search:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
#agent_search label {
	float: left;
	width: 20%;
	background-color: #cecece;
	line-height: 33px;
	text-transform: uppercase;
	text-align: center;
	display: inline-block;
	position: relative;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
#agent_search label:before {
	pointer-events: none;
	position: absolute;
	z-index: -1;
	content: '';
	border-style: solid;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-property: right;
	transition-property: right;
	top: calc(50% - 10px);
	right: 0;
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent #cecece;
}
#agent_search:hover label:before, #agent_search label:focus:before, #agent_search label:active:before {
	right: -10px;
}
#agent_search .agent_text_container {
	float: left;
	width: 80%;
}

#agent_search .agent_text_container #agent_submit{
	background-color: #8A0726;	
	border: none;
	border-top: 2px solid #B1072F;
	border-left: 1px solid #B1072F;
	color: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;	
	height: 30px;
	font-size: 14px;
	padding: 0 !important;
	margin: 3px 0;
	cursor: pointer;
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: bold;
	float: left;
	width: 20%;
}

#agent_search .agent_text_container #agent_submit:hover{background-color: #B1072F;}
#agent_search .agent_text_container #agent_name_text {
	float: left;
	width: 80%;
	padding-left: 15px;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.agents_list li .agents{display: block;}
.agents_list li .agents .agent_constraint {
	margin-right: 10px;
	width: 100%;
	height: 195px;
	overflow: hidden;
	border:solid 3px #cecece;
	background-repeat: no-repeat;background-size: cover;background-position: top center;
}
.agents_list li .agents .agent_constraint.company_class{background-size: 90%;background-position: center center;}
.agents_list li .agents .agent_constraint{ min-width: 120px; }

.agents_list li .agents .agent_content .agentName{font-weight: 700;}
.agents_list li .agents .agent_content .agentTitle{color: #8A0726;}
.agents_list li .agents .agent_content a:hover {color: #000;}
.agents_list li .agents .agentOther{
	background-color: #8A0726;	
	border: none;
	border-top: 2px solid #B1072F;
	border-left: 1px solid #B1072F;
	color: #fff;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;	
	height: 30px;
	font-size: 14px;
	padding: 0 !important;
	margin: 3px 0;
	cursor: pointer;
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: bold;
	text-align: center;
}
.agents_list li .agents .agentOther:hover{background-color: #B1072F;}

/***________CONTACT US_______****/


#contact-form input[type='text'].error, #contact-form input[type='email'].error {
	background-color: #F9F2F4;
	color: #C7254E;
	margin-bottom: 10px;
}
#contact-form textarea.error {
	background-color: #F9F2F4;
	color: #C7254E;
	margin-bottom: 10px;
}
#contact-form label.error {
	display: none !important;
}
/***_____-FEATURED________*****/

.featured_ammenities {
	list-style: none;
	padding: 0;
	margin-top: 40px;
}
.featured_ammenities li {
	margin: 0;
	padding:0;
}
.featured_box {
	background: transparent url('../images/featured_bg.png') no-repeat center center;
	color: #222323;
	width: 351px;
	height: 279px;
	padding: 20px;
}
.featured_box .property_image {
	width: 313px;
	height: 165px;
	border: solid 1px #b4c3d0;
	overflow: hidden;
	margin-bottom: 5px;
}
.featured_box .property_image img {
	width: 100%;
}
.featured_box .property_description {
	color: #000;
	font-size: 12px;
	line-height: 15px;
}
.learn_more_link {
	width: 251px;
	text-align: center;
	background-color: #003366;
	font-size: 15px;
	line-height: 30px;
	color: #fff;
	text-transform: uppercase;
	display: block;
	margin-top: 20px;
}
/*****_____-TWITTER_______******/


/****MORTGAGE CALCULATOR*****/
#MortgageCal table {
	background-color: #304588 !important;
	border: none;
	padding:0;
	margin:0;
}
#MortgageCal table tr td {
	background-color: #304588 !important;
	border-spacing: 0;
	border: none;
	padding:0;
	margin:0;
	vertical-align: middle;
}
#MortgageCal table tr td * {
	padding:0;
	margin:0;
}
#MortgageCal table tr.top_row td {
	background-color: #fff !important;
}
#MortgageCal table tr td input[type='text'] {
	width: auto !important;
	float:left;
}
.flags_row tr td, .flags_row tr th {
	text-align: left;
	background-color: #304588 !important;
}
.flags_row tr td label, .flags_row tr th label {
	float: left;
	margin: 0 10px !important;
}
#calculator_pop.reveal-modal {
	padding: 0 !important;
	z-index: 10000;
}
/****END MORTGAGE CALCULATOR*****/


/***********VIDEO CSS***************/

iframe#player {
	margin-top: 0 !important;
}
.videogrid .img {
	width: 302px;
	height: 184px;
}
.videogrid .img img {
	width: 100%;
	height: 184px;
}
.vidoverlay {
	background: url("../images/video_bottom.png") no-repeat scroll left top transparent;
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 1000;
}
/*******MEMBER LOGIN**********/
.facebook_log {
	float: left;
}
.google_log {
	float: right;
}
.login_box {
	background: none repeat scroll 0 0 #f9f9f9;
	border: 1px solid #d5d5d5;
	border-radius: 3px;
	box-shadow: 0 0 2px #dadada, 0 -3px 0 #e6e6e6 inset;
	display: block;
	margin: 50px auto 0;
	width: 90%;
}
.login_box #content {
	padding: 23px 28px 0;
}
.login_box #content h1 {
	border-bottom: 1px solid #e0e0e0;
	color: #003366;
	font-size: 20px;
	padding: 0 0 13px;
}
.login_box #content .social {
	height: 75px;
	padding: 13px 0 0;
}
.login_box #content .social .twitter {
	background: url("../images/twitter_btn.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
	display: block;
	float: left;
	height: 32px;
	margin-top: 10px;
	width: 157px;
}
.login_box #content input[type='submit'] {
	width: 100%;
	margin-top: 10px;
	background-color: #003366;
}
.fb {
	background: url("../images/fb_btn.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
	display: block;
	float: right;
	height: 32px;
	margin-top: 10px;
	width: 157px;
}
.btn_1 {
	color: #fff;
	display: block;
	font-size: 12px;
	font-weight: 700;
	height: 32px;
	padding: 8px 0 0 35px;
	text-shadow: -1px -1px 0 #0083b3;
}
.btn_2 {
	color: #fff;
	display: block;
	font-size: 12px;
	font-weight: 700;
	height: 32px;
	padding: 8px 0 0 31px;
	text-shadow: -1px -1px 0 #366c96;
}
#login {
	border-top: 1px solid #e0e0e0;
	padding: 13px 0 0;
}
.user {
	background: url("../images/user.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0) !important;
}
.login {
	height: auto !important;
	background-color: #fdfdfd;
	border: 1px solid #d8d8d8 !important;
	border-radius: 3px !important;
	box-shadow: 2px 2px 4px #f1f1f1 inset !important;
	color: #8e8d8d !important;
	display: block !important;
	font-size: 14px !important;
	margin: 13px 0 -3px !important;
	padding: 11px 15px 10px 50px !important;
	width: 100% !important;
}
.password {
	background: url("../images/password.png") no-repeat scroll 0 0 rgba(0, 0, 0, 0) !important;
}
#bottom_text {
	background-color: rgba(255, 255, 255, 0.9);
	display: block;
	line-height: 19px;
	margin: 12px auto 2px 0;
	padding: 12px;
	text-align: center;
	text-shadow: 1px 1px 0 #fff;
	border-top: 1px solid #ccc;
}
#submit_login_request, #complete_submit {
	width: 100%;
	margin-top: 10px;
	background-color: #003366;
}
/********BANNER-SIDE**********/
.advtitle {
	line-height: 1px;
	background-color:#ddd;
}
.advtitle span {
	display: inline-block;
	background-color: #fff;
	padding: 3px;
	color: #ddd;
}
.side_banner { width: 150px; display: block; margin-bottom: 20px;}
.pdetailsBanner .side_banner{width: 100%;}


/****POPUP STYLE*****/
.reveal-modal.popUpStyle {
	padding: 0;
	text-align: center;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	z-index: 10000;
}
.reveal-modal.popUpStyle h2 {
	background-color: #F0F0F1;
	border-bottom: 1px solid #bababa;
	padding: 10px 0;
	text-align: center;
	font-weight: bold;
	color: #333333;
	-webkit-border-top-left-radius: 10px;
	-webkit-border-top-right-radius: 10px;
	-moz-border-radius-topleft: 10px;
	-moz-border-radius-topright: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.reveal-modal.popUpStyle p { text-align: center; width: 70%; margin: 20px auto 10px auto; }
.reveal-modal.popUpStyle input[type='button'] {
	width: 144px; text-transform: uppercase;
    transition: all .3s ease;
    background-color: #8A0726;
    border: none;
    border-top: 2px solid #B1072F;
    border-left: 1px solid #B1072F;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    height: 30px;
    font-size: 14px;
    padding: 0 !important;
    margin: 10px 0;
    cursor: pointer;
    font-weight: 700;
}

.reveal-modal.popUpStyle input[type='button'].yes {
	background-color: #0d8a07;
	border-top: 2px solid #009933;
    border-left: 1px solid #009933;
}
/*****8EDIT PROFILE******/
.addtoMail .rb:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.addtoMail .rb {
	float: left;
	margin-right: 10px;
}
.addtoMail .rb * {
	margin-right: 5px;
	float: left;
}
.mailOptions .rb {
	float: left;
	margin-right: 5px;
}
.mailOptions .rb:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.mailOptions .rb input {
}
.mailOptions .rb * {
	float: left;
	margin-right: 5px;
}
.mailing_style {
	background-color: #fafafa;
	border: 1px solid #eee;
	padding: 10px;
	margin: 0 10px;
}
.mailing_style select {
	background-color: #fff;
}
.propertyTypeContainer .propertyTypeList {
	float: left;
	width: 49%;
}
.propertyTypeContainer .propertyTypeList:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}
.propertyTypeContainer .propertyTypeList * {
	float: left;
	margin-right: 5px;
}


/*---------------------------- Footer Area --------------------------------*/
.company_footer a[href^=tel] {color: #fff;}
#footer{
	padding-top: 30px;
	padding-bottom: 15px;
    background-color: #e5e5e5;
    border-top: 1px solid #d9d9d9;
    color: #000;
}
#footer h1,
#footer h2,
#footer h3,
#footer h4,
#footer h5,
#footer h6{
	
    color: #444444;
    font-weight: normal;	
    font-size: 24.5px;
    line-height: 40px;
}

#footer a.footer_link {
    background: #333333;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    border: none;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    padding: 5px;
    cursor: pointer;
}
#footer a.footer_link:hover{background-color: #780023; }

#footer .copyright{font-size: 90%; border-top: 1px solid #fff; box-shadow: 0 -1px #d1d1d1;margin-top: 20px;padding-top: 20px;color: #666666;}

.navigation_list { list-style: none; margin-left: 10px; }
.navigation_list li a { color: #000; font-size: 90%; }
.navigation_list li a:hover { color: #55acee; }


div.footer-text { font-size: 10px; }
div.footer-text .container, div.footer-text .container p { width: 95%; margin: 15px auto 0 auto; font-size: 10px;}


.parallax{
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
	position: relative;
	    
}

#banner_slider { margin: 20px auto 0 auto; }
#banner_slider .bx-wrapper .bx-viewport { background: none; border: none; box-shadow: none; left: 0;}


#slidets { left: 0; margin-left: 0;}
#slidets li a{ display: block;height: 152px;width: 100%;-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px;overflow: hidden;}
#slidets li a img{width: 100%;min-height: 152px;}


.property_courtesy{color: #000;margin-top: 5px;display: block;}
.property_courtesy abbr{color: #000;}

.agent_details_form.share_with_me .container2{border: none !important;}
.agent_details_form.share_with_me .property_courtesy{color: #003366 !important;}
.agent_details_form.share_with_me .property_courtesy abbr{color: #003366 !important;}




	
#social_icons {text-align: center;}
#social_icons ul {
    padding: 0;
    margin: 0;
}
#social_icons ul li {
    list-style-type: none;
    display: inline-block;
    padding: 0;
    margin: 0;
}

#social_icons ul li.social_facebook a {
    background-position: 0 0;
}
#social_icons ul li.social_google a {
    background-position: -79px 0;
}
#social_icons ul li.social_linkedin a {
    background-position: -160px 0;
}

#social_icons ul li a {
    background-image: url(../images/social_icons.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    height: 35px;
    margin-right: -5px;
    opacity: .5;
    display: inline-block;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

#social_icons ul li a span{display: none;}

#social_icons ul li:hover:not(.social_linkedin) a{opacity: 0.15;}
#social_icons ul li:hover:not(.social_google) a{opacity: 0.15;}
#social_icons ul li:hover:not(.social_facebook) a{opacity: 0.15;}


.totalListShow .title_label,
.search_options .title_label{line-height: 30px;}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../font/helveticaneue-webfont.eot'); /* IE9 Compat Modes */
  src: url('../font/helveticaneue-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/helveticaneue-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url('../font/helveticaneue-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../font/helveticaneue-webfont.svg#Helvetica Neue') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../font-awesome/fonts/fontawesome-webfont.eot?v=4.4.0');
  src: url('../font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.4.0') format('embedded-opentype'), 
  		 url('../font-awesome/fonts/fontawesome-webfont.woff2?v=4.4.0') format('woff2'), 
  		 url('../font-awesome/fonts/fontawesome-webfont.woff?v=4.4.0') format('woff'), 
  		 url('../font-awesome/fonts/fontawesome-webfont.ttf?v=4.4.0') format('truetype'), 
  		 url('../font-awesome/fonts/fontawesome-webfont.svg?v=4.4.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*-------------------- Small screens -------------------------*/
@media only screen {
     input[type='radio']{width: auto;}
}
/* Define mobile styles */

@media only screen and (max-width: 40em) { 
	.featured_grouping li{width: 49%;}
	.featured_grouping li a{width: 100%;}
	.mFeaturedBox{margin: 0 auto;}
}
/* max-width 640px, mobile-only styles, use when QAing mobile issues */

/* Smartphones (portrait and landscape) ----------- */
@media  only screen and (min-width : 320px) and (max-width : 480px) { /* Styles */

	.nopadding{padding-left: 15px !important; padding-right: 15px !important;}
	.nopadding-right{padding-right:  15px !important;}
	.nopadding-left{padding-left:  15px !important;}
	
	.sm-nopadding{padding:  0 !important;}
	.sm-nopadding-right{padding-right:  0 !important;}
	.sm-nopadding-left{padding-left:  0 !important;}
	
	.site_logo_header{text-align: center;}

	header .first_section{text-align: center;}
	header .first_section .contact_info{ text-align: center !important;padding: 10px 0;}

	#main_navigation{float: left;background-color: #8A0726;}
	.top-bar-section .dropdown{background-color: #8A0726;}

	#navigation.nav.fixed{background: #EEEEEE !important;}
	#navigation.nav.fixed2 #main_navigation li, .inside_page #main_navigation li{margin: 0;}
	#navigation{padding-bottom: 0 !important;}
	#navigation .row .columns{padding: 0 !important;}

	.top-bar{margin-top: 10px;}
	.top-bar{background-color: #8A0726;}
	.top-bar .title-area li a{color: #fff !important;}

	.top-bar .toggle-topbar.menu-icon a span{font-family: Arial;	}
	.top-bar .toggle-topbar.menu-icon a span::after{box-shadow: 0 0 0 1px #ffffff, 0 5px 0 1px #ffffff, 0 10px 0 1px #ffffff;top: 54%;}
	
	.top-bar-section .dropdown li.title h5 a{color: #f7ff00;}
	.top-bar-section .dropdown li.parent-link a{font-weight: 700; font-family: Arial; background-color: rgba(0, 0, 0, 0.1);}

	.md-search{ padding-left: 0.9375rem; padding-right: 0.9375rem; }
	.commercial_ad{ padding-left: 0.9375rem;}
	.magazine_ad{padding-right: 0.9375rem;}

	.pr5{ padding-right: 0.9375rem; } 
	.pl15{ padding-left: 0.9375rem; }
	
	#bedroom_bathroom_input_div .columns{padding: 0;}
	
	.fSlide{min-height: 675px;}
	.front_slider li{min-height: 675px;}
	
	.white_section{padding-left: 0.9375rem; padding-right: 0.9375rem;}
	.recent_properties{margin: 0 !important;}
	
	
	#slidets li a{ display: block;height: 40px;width: 100%;-webkit-border-radius: 5px; -moz-border-radius: 5px; border-radius: 5px;overflow: hidden;}
	#slidets li a img{width: 100%;min-height: 40px;}
	
	
	table{width: 100%;}
	.tabs dd { float: left; margin: 0 5px 0 7px; }
	.vidoverlay { background: url("../images/video_bottom.png") no-repeat scroll left top transparent; display: block; height: 100%; position: absolute; width: 100%; z-index: 1000; }

	
	.results_set{width: 100%;}
	
	.search_class{margin-top: 20px;}
	.search_area2{margin-bottom: 15px;}
	#base_search .mask, .top_row .mask{margin-bottom: 5px !important;}
	#keywords{margin-bottom: 15px;}
	
	.search_class .search-button.search{ -webkit-border-top-left-radius: 4px; -webkit-border-top-right-radius: 4px; -moz-border-radius-topleft: 4px; -moz-border-radius-topright: 4px; border-top-left-radius: 4px; border-top-right-radius: 4px; }
	.search_class .search-button.reset{ -webkit-border-bottom-right-radius: 4px; -webkit-border-bottom-left-radius: 4px; -moz-border-radius-bottomright: 4px; -moz-border-radius-bottomleft: 4px; border-bottom-right-radius: 4px; border-bottom-left-radius: 4px; }
	
	.range { width: 50%; }
	.topButtons a { display: inline-block; float: right; margin-top: 10px; padding: 0 10px; width: 50%; }
	
	.grid_search li a{display: block;}
	.grid_search li a .box{display: block;width: 100%;}
	.grid_search li a .box .property_image_holder{width: 100%;height: 200px;}
	.grid_search li a .box .property_image_holder .property_img{min-height: 200px;}
	
	#header{min-height: 158px;}
	
	.side_banner{width: 100%;}
	
	.action_items { width: 100%; }
	.action_items a {display: block;width: 100%; background: transparent url('../images/propbar.png') repeat scroll left center;height: 30px;line-height: 30px;color: #fff; }
	.action_items a i{color: #fff;width: 16px;}
	.action_items a:hover { background: rgba(0, 51, 102, 1) !important;color: #fff;}
	
	.traverse_block a{margin-bottom: 10px;}
	
	.social_details .traverse_block{width: 100%;}
	.social_details .traverse_block a { width: 100%; margin-left: 0; }
	
	.propertySlide li .image_hider{ width: 100%; height: auto; min-height: 250px !important;}
	.contactsection { margin-left: 0; padding-left: 0; }

	.facebook_log, .google_log{width: 100%;}
	.facebook_log a, .google_log a{width: 100%; display: block;}
	
	.facebook_log{margin-bottom: 5px;}
	.google_log{margin-bottom: 20px;}
	
	.locationAll{margin-top: 10px;}
	.amenitiesAll { padding-bottom: 10px; margin-top: 10px;}
	
	.videogrid .img{width: 100%;height: auto !important;}
	.vidoverlay { background: url("../images/video_bottom_2.png") no-repeat scroll center center transparent !important; display: block; height: 100%; position: absolute;  width: 100%; z-index: 1000; }
	#footer .row{width: 100% !important;}
	.twitter_footer{margin: 30px 0;}
	.company_footer a[href^=tel] {color: #fff;}
	#agent_search .agent_text_container #agent_submit{-webkit-appearance: none;}
	
	.topDetails2 .addressSection{text-align: left;margin: 10px 0;}

	.property_details_slider li{min-height: 400px;}

	.agents_list li .agents .agent_constraint {
		height: 350px;
	}
}

@media screen and (min-width : 320px) and (max-width : 480px) and (orientation : landscape) { /* Styles */
	.grid_search li a .box .property_image_holder{width: 100%;height: 250px;}
	.grid_search li a .box .property_image_holder .property_img{min-height: 250px;}

	#slidets li a{height: 55px;}
	#slidets li a img{min-height: 55px;}
}


@media screen and (min-width : 360px) and (max-width : 640px) and (orientation : landscape) { /* Styles */
	.md-nopadding{padding-right:  0 !important;padding-left:  0 !important;}
	.md-nopadding-right{padding-right:  0 !important;}
	.md-nopadding-left{padding-left:  0 !important;}
	
	#slidets li a{height: 70px;}
	#slidets li a img{min-height: 70px;}

	.property_details_slider li{min-height: 460px;}
}

@media screen and (min-width : 360px) and (max-width : 640px) { /* Styles */
	#navigation.nav.fixed{background: #EEEEEE !important;}
	#navigation.nav.fixed2 #main_navigation li, .inside_page #main_navigation li{margin: 0;}
	
	.nopadding{padding-left:  15px !important;padding-right:  15px !important;}
	.nopadding-right{padding-right:  15px !important;}
	.nopadding-left{padding-left:  15px !important;}
	
	.site_logo_header{text-align: center;}

	#main_navigation{float: left;}

	#navigation{padding-bottom: 0 !important;}
	#navigation .row .columns{padding: 0 !important;}

	.top-bar{margin-top: 10px;}
	.top-bar{background-color: #8A0726;}
	.top-bar .title-area li a{color: #fff !important;}
	
	.md-search{ padding-left: 0.9375rem; padding-right: 0.9375rem; }
	.commercial_ad{ padding-left: 0.9375rem;}
	.magazine_ad{padding-right: 0.9375rem;}
	
	.pr5{ padding-right: 0.9375rem; }
	.pl5{ padding-left: 0.9375rem; }
	.pl10{ padding-left: 0.9375rem; }
	.pl15{ padding-left: 0.9375rem; }
	
	#bedroom_bathroom_input_div .columns{padding: 0 !important;}
	
	.fSlide{min-height: 675px;}
	.front_slider li{min-height: 675px;}
	
	.white_section{padding-left: 0.9375rem; padding-right: 0.9375rem;}
	.recent_properties{margin: 0 !important;}
	#agent_search .agent_text_container #agent_submit{-webkit-appearance: none;}
	
	.search_class{margin-top: 20px;}
	.search_area2{margin-bottom: 15px;}
	#base_search .mask, .top_row .mask{margin-bottom: 5px !important;}
	#keywords{margin-bottom: 15px;}
	
	.search_class .search-button.search{
		-webkit-border-top-left-radius: 4px;
		-webkit-border-top-right-radius: 4px;
		-moz-border-radius-topleft: 4px;
		-moz-border-radius-topright: 4px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	
	.search_class .search-button.reset{
		-webkit-border-bottom-right-radius: 4px;
		-webkit-border-bottom-left-radius: 4px;
		-moz-border-radius-bottomright: 4px;
		-moz-border-radius-bottomleft: 4px;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}	
	
	.results_set{width: 100%;}
	
	.range { width: 50%; }
	.topButtons a { display: inline-block; float: right; margin-top: 10px; padding: 0 20px; width: 50%; }
	
	.action_row { background: url("../images/propbar.png") repeat-x scroll left top #17426f; color: #ffffff; height: auto !important; margin-bottom: 8px; margin-top: 3px; }
	
	.grid_search .box{width: 100%;}
	.grid_search li a .box .property_image_holder{width: 100%;height: 350px;}
	.grid_search li a .box .property_image_holder .property_img{min-height: 350px;}
	
	.contactsection { margin-left: 0; padding-left: 0; }
	
	.locationAll{margin-top: 10px;}
	.amenitiesAll { padding-bottom: 10px; margin-top: 10px;}
	
	
	.videogrid .img{width: 100%;height: auto !important;}
	.videogrid .img img{height: auto !important;	}
	.vidoverlay { background: url("../images/video_bottom_2.png") no-repeat scroll center center transparent !important; display: block; height: 100%; position: absolute;  width: 100%; z-index: 1000; }
	
	#footer .row{width: 100% !important;}
	.twitter_footer{margin-bottom: 15px;}
	.topDetails2 .addressSection{text-align: left;margin: 10px 0;}
}
 
/* Smartphones (landscape) ----------- */
@media  only screen and (min-width : 321px) { }
 
/* Smartphones (portrait) ----------- */
@media  only screen and (max-width : 320px) { }

/* iPads (portrait and landscape) ----------- */
@media  only screen and (min-width : 641px) and (max-width : 767px) { /* Styles */
	#main_navigation li{margin-right: 4px;}
	.tabs-content > .content{ padding: 15px 15px;}
	
	.white_bottom{border-top: 1px solid rgba(0, 0, 0, 0.3);padding: 0 10px;}
	
	#navigation.nav.fixed{background: #EEEEEE !important;}
	#navigation.nav.fixed2 #main_navigation li, .inside_page #main_navigation li{margin: 0;}
	
	.search_class {height: auto !important;left: 0;margin: 10px auto;right: 0;width: 90%;}
	.search_class label { display: none; }
	.search_class .columns{width: 100%;}
	.search_class .mask{width: 100%;display: block;margin: 0 0 5px 0 !important;}
	.search_class .mask select{width: 100%;}
	.search_class #bedroom_bathroom_input_div .removeright select{width: 100%;}
	
	.white_bottom{border-top: 1px solid rgba(0, 0, 0, 0.3);padding: 0 10px;}
	
	#banner_slider{width: 100% !important;}
	#slidets li a{display: block;width: 100%;height: 100px;}
	#slidets li img{width: 100%;min-height: 100px;}
	
	
	.commercial_logo a{display: block;width: 100%;margin: 0 0 10px 0;text-align: left;}
	.commercial_text{margin-bottom: 10px !important;}
	
	.side_banner{float: left;margin-right: 10px;width: 138px;}
	
	.action_row {
	    background: url("../images/propbar.png") repeat-x scroll left top #17426f;
	    color: #ffffff;
	    height: auto !important;
	    margin-bottom: 8px;
	    margin-top: 3px;
	}
	
	.property_details_slider li{min-height: 540px;}

	.facebook_log, .google_log{width: 100%;display: block;margin-bottom: 5px;	}
	.facebook_log a, .google_log a{width: 100%; display: block;}
	
	.videogrid .img{width: 100%;height: auto !important;}
	.vidoverlay { background: url("../images/video_bottom_2.png") no-repeat scroll center center transparent !important; display: block; height: 100%; position: absolute;  width: 100%; z-index: 1000; }
	
	
	#footer .row{width: 100% !important;}
	.twitter_footer{margin-bottom: 15px;}
	
}


/* iPads (portrait and landscape) ----------- */


@media  only screen and (min-width : 768px) and (max-width : 1024px) { /* Styles */
	
	#header{min-height: 150px;}
	.site_logo_header{text-align: center;}

	.fSlide{min-height: 500px;	}
	.front_slider li{min-height: 500px;}
	
	/*.search_area2{padding-left: 0 !important;}*/
	.search_area3{padding-right: 0 !important;}
	
	.white_section{padding-left: 0.9375rem; padding-right: 0.9375rem;}
	
	.side_banner{float: left;margin-right: 10px;width: 138px;}
	#agent_search .agent_text_container #agent_submit{-webkit-appearance: none;}
	
	#slidets li a{height: 85px;}
	#slidets li a img{min-height: 85px;}
	
	
	.md-search{ padding-left: 0.9375rem; padding-right: 0.9375rem; }
	.commercial_ad{ padding-left: 0.9375rem;}
	.magazine_ad{padding-right: 0.9375rem;}
	
	.action_row { background: url("../images/propbar.png") repeat-x scroll left top #17426f; color: #ffffff; height: auto !important; margin-bottom: 8px; margin-top: 3px; }
	.search_class.inner .tabs-content > .content > form input[type="reset"] { width: 85px; }
	.facebook_log, .google_log{width: 100%;display: block;margin-bottom: 5px;	}
	.facebook_log a, .google_log a{width: 100%; display: block;}
	
	.videogrid .img{width: 100%;}
	
	#footer{height: auto;}
	#footer .row{width: 100% !important;}
	.twitter_footer{margin-bottom: 15px;}
	
	.footer_div{min-height: 290px;margin-bottom: 30px;}
	.topDetails2 .addressSection{text-align: left;margin: 10px 0;}


	.property_details_slider li{min-height: 485px;}
	
}
 
@media  only screen and (min-width : 800px) and (max-width : 800px) and (orientation : landscape) {
	.filter_set{display: 	none !important;}
	.results_set{width: 100% !important;}
	.company_footer{margin-bottom: 30px;}
}
/* iPads (landscape) ----------- */
@media  only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : landscape) {
/* Styles */
	
	.md-land-2{width: 16.6667% !important;}
	.md-land-10{width: 83.3333% !important; }
	
	#slidets li a{height: 110px;}
	#slidets li a img{min-height: 110px;}
	
	
	.top-bar-section li:not(.has-form) a:not(.button){padding: 0 8px;}
	.company_footer{margin-bottom: 30px;}

}
 
/* iPads (portrait) ----------- */
@media  only screen and (min-width : 768px) and (max-width : 1024px) and (orientation : portrait) { /* Styles */
	.nopadding{padding-left:  15px !important;padding-right:  15px !important;}
	.nopadding-right{padding-right:  15px !important;}
	.nopadding-left{padding-left:  15px !important;}
	
	.rentsale_div_container{padding: 0 !important;}
	.top-bar-section .has-dropdown > a::after{top: 15.5px;}
	
	.contactsection { margin-left: 0; padding-left: 0; }
	.company_footer{margin-bottom: 30px;}

	#slidets li a{height: 107px;}
	#slidets li a img{min-height: 107px;}
	
	.featured_block{padding-left: 0.9375rem; padding-right: 0.9375rem;}
	
	.results_set{width: 100%;}
	.filter_set{display: none !important;}

	.agents_list li .agents .agent_constraint {
		height: 350px;
	}
}



/* Desktops and laptops ----------- */
@media  only screen and (min-width : 1224px) { 
	/* Styles */
}
 
/* Large screens ----------- */
@media  only screen and (min-width : 1824px) {
	/* Styles */
}
 
/* iPhone 4 ----------- */
@media screen and (-webkit-min-device-pixel-ratio : 1.5), screen and (min-device-pixel-ratio : 1.5) {
	
	.sm-nopadding{padding:  0 !important;}
	.sm-nopadding-right{padding-right:  0 !important;}
	.sm-nopadding-left{padding-left:  0 !important;}
	
 	.top-bar{margin-top: 10px;}
	table{width: 100%;border: none !important; border-spacing: 0 !important;}
	.tabs dd { float: left; margin: 0 5px 0 7px; }
	.commercial_logo a{display: block;width: 100%;margin: 0 0 10px 0;text-align: center;}
	.commercial_text{margin-bottom: 10px !important;}

	.topButtons a { display: inline-block; float: right; margin-top: 10px; padding: 0 20px; width: 50%; }
	
	.grid_search li a{display: block;}
	.grid_search li a .box{display: block;width: 100%;}
	.grid_search li a .box .property_image_holder{width: 100%;height: 200px;}
	.grid_search li a .box .property_image_holder .property_img{min-height: 200px;}
	
	.side_banner{width: 100%;}
	
	
	.action_row .rentsale_div { width: auto !important; }
	.action_items { width: auto !important; }
	.action_items a { width: auto !important;}
	.action_items a:hover { }
	
	#agent_search .agent_text_container #agent_submit{-webkit-appearance: none;}
	.social_details .traverse_block{width: auto !important;}
	.social_details .traverse_block a {width: auto !important; margin-left: 0; }
	
	.propertySlide li .image_hider img{ width: 100%; min-height: inherit !important; height: auto !important; }
	.contactsection { margin-left: 0; padding-left: 0; }
	
	
	.facebook_log, .google_log{width: 100%;margin-bottom: 5px;}
	.facebook_log a, .google_log a{width: 100%; display: block;}
	.google_log{margin-bottom: 20px;}
	
	#footer .row{width: 100% !important;}
	.twitter_footer{margin-bottom: 15px;}
	
	.locationAll{margin-top: 10px;}
	.amenitiesAll { padding-bottom: 10px; margin-top: 10px;}
	.topDetails2 .addressSection{text-align: left;margin: 10px 0;}
	
} 



